import card0 from './images/cards/Throne rooms/1_Blue_Mullett_ThroneRoom.png';
import card1 from './images/cards/Throne rooms/2_White_Human_ThroneRoom.png';
import card2 from './images/cards/Throne rooms/3_Green_Oni_ThroneRoom.png';
import card3 from './images/cards/Throne rooms/4_Cyan_Omnian_ThroneRoom.png';
import card4 from './images/cards/Throne rooms/5_Red_Owlbeari_ThroneRoom.png';
import card5 from './images/cards/Throne rooms/6_Orange_Scramlin_ThroneRoom.png';
import card6 from './images/cards/Throne rooms/7_Black_Shoggoth_ThroneRoom.png';
import card7 from './images/cards/Throne rooms/8_Pink_Gubbin_ThroneRoom.png';
import card8 from './images/cards/Throne rooms/9_Purple_Saurac_ThroneRoom.png';
import card9 from './images/cards/Throne rooms/10_Yellow_Krake_ThroneRoom.png';
import card10 from './images/cards/1_BlackMarket_EFFECT_ROOM.png';
import card11 from './images/cards/2_DivinationChamber_EFFECT_ROOM.png';
import card12 from './images/cards/3_MerchantsGuild_EFFECT_ROOM.png';
import card13 from './images/cards/4_WormholeGenerator_EFFECT_ROOM_Template.png';
import card14 from './images/cards/5_Doomsayer_EFFECT_ROOM.png';
import card15 from './images/cards/6_AntigravityCannon_EFFECT_ROOM.png';
import card16 from './images/cards/7_TimeMachine_EFFECT_ROOM.png';
import card17 from './images/cards/8_SoothSayer_EFFECT_ROOM.png';
import card18 from './images/cards/9_Architect_EFFECT_ROOM.png';
import card19 from './images/cards/10_InteriorDecorator_EFFECT_ROOM.png';
import card20 from './images/cards/11_Treasury_EFFECT_ROOM.png';
import card21 from './images/cards/12_Market_EFFECT_ROOM.png';
import card22 from './images/cards/13_Energycore_EFFECT_ROOM.png';
import card23 from './images/cards/14_GravityGenerator_EFFECT_ROOM.png';
import card24 from './images/cards/15_ReinforcedWalls_EFFECT_ROOM.png';
import card25 from './images/cards/16_GravitationalAnchor_EFFECT_ROOM.png';
import card26 from './images/cards/17_Tavern_EFFECT_ROOM.png';
import card27 from './images/cards/18_Echochamber_EFFECT_ROOM.png';
import card28 from './images/cards/19_Armorer_EFFECT_ROOM.png';
import card29 from './images/cards/20_Generator_EFFECT_ROOM.png';
import card30 from './images/cards/21_Generator_EFFECT_ROOM.png';
import card31 from './images/cards/22_SafeRoom_EFFECT_ROOM.png';
import card32 from './images/cards/23_Bunker_EFFECT_ROOM.png';
import card33 from './images/cards/24_RoomWithLegs_EFFECT_ROOM.png';
import card34 from './images/cards/25_SpinningRoom_EFFECT_ROOM.png';
import card35 from './images/cards/26_WarpingChamber_EFFECT_ROOM.png';
import card36 from './images/cards/27_ImposterEFFECT_ROOM.png';
import card37 from './images/cards/28_CommandersChambers_EFFECT_ROOM.png';
import card38 from './images/cards/29_EngineersWorkshop_EFFECT_ROOM.png';
import card39 from './images/cards/30_PilotsQuarters_EFFECT_ROOM.png';
import card40 from './images/cards/31_swimmingpool_EFFECT_ROOM.png';
import card41 from './images/cards/32_CandyShop_EFFECT_ROOM.png';
import card42 from './images/cards/33_PettingZoo_EFFECT_ROOM.png';
import card43 from './images/cards/34_MovieTheatre_EFFECT_ROOM.png';
import card44 from './images/cards/35_NuclearReactor_EFFECT_ROOM.png';
import card45 from './images/cards/36_Mercenary_EFFECT_ROOM.png';
import card46 from './images/cards/37_Mercenary_EFFECT_ROOM.png';
import card47 from './images/cards/38_Mercenary_EFFECT_ROOM.png';
import card48 from './images/cards/39_Mercenary_EFFECT_ROOM.png';
import card49 from './images/cards/40_GiantFan_EFFECT_ROOM.png';
import card50 from './images/cards/41_GiantFan_EFFECT_ROOM.png';
import card51 from './images/cards/42_ConstructionCre_EFFECT_ROOM.png';
import card52 from './images/cards/43_RocketEngineer_EFFECT_ROOM.png';
import card53 from './images/cards/44_RocketEngineer_EFFECT_ROOM.png';
import card54 from './images/cards/45_Shuttle_EFFECT_ROOM.png';
import card55 from './images/cards/46_Shuttle_EFFECT_ROOM.png';
import card56 from './images/cards/47_WarpCannon_EFFECT_ROOM.png';
import card57 from './images/cards/48_WarpCannon_EFFECT_ROOM.png';
import card58 from './images/cards/49_WarpCannon_EFFECT_ROOM.png';
import card59 from './images/cards/50_HydraulicArm_EFFECT_ROOM.png';
import card60 from './images/cards/51_HydraulicArm_EFFECT_ROOM.png';
import card61 from './images/cards/52_WatchTower_EFFECT_ROOM.png';
import card62 from './images/cards/53_HomeownersAssociation_EFFECT_ROOM.png';
import card63 from './images/cards/54_unhappyNeighbour_EFFECT_ROOM.png';
import card64 from './images/cards/55_Disco_EFFECT_ROOM.png';
import card65 from './images/cards/Blankroom/1_Corridor1_BLANKROOM.png';
import card66 from './images/cards/Blankroom/2_Corridor2_BLANKROOM.png';
import card67 from './images/cards/Blankroom/3_Corridor3_BLANKROOM.png';
import card68 from './images/cards/Blankroom/4_Corridor4_BLANKROOM.png';
import card69 from './images/cards/Blankroom/5_Corridor5_BLANKROOM.png';
import card70 from './images/cards/Blankroom/6_Corridor6_BLANKROOM.png';
import card71 from './images/cards/Blankroom/7_Hallway1_BLANKROOM.png';
import card72 from './images/cards/Blankroom/8_Hallway2_BLANKROOM.png';
import card73 from './images/cards/Blankroom/9_Hallway3_BLANKROOM.png';
import card74 from './images/cards/Blankroom/10_Hallway4_BLANKROOM.png';
import card75 from './images/cards/Blankroom/11_Hallway5_BLANKROOM.png';
import card76 from './images/cards/Blankroom/12_Hallway6_BLANKROOM.png';
import card77 from './images/cards/Blankroom/13_Hallway7_BLANKROOM.png';
import card78 from './images/cards/Blankroom/14_Staircase1_BLANKROOM.png';
import card79 from './images/cards/Blankroom/15_Staircase2_BLANKROOM.png';
import card80 from './images/cards/Blankroom/16_Staircase3_BLANKROOM.png';
import card81 from './images/cards/Blankroom/17_Staircase4_BLANKROOM.png';
import card82 from './images/cards/Blankroom/18_Staircase5_BLANKROOM.png';
import card83 from './images/cards/Blankroom/19_Staircase6_BLANKROOM.png';
import card84 from './images/cards/Blankroom/20_Staircase7_BLANKROOM.png';
import card85 from './images/cards/Vaults/1_BigVAULT1.png';
import card86 from './images/cards/Vaults/2_BigVAULT2.png';
import card87 from './images/cards/Vaults/3_BigVAULT3.png';
import card88 from './images/cards/Vaults/4_BigVAULT4.png';
import card89 from './images/cards/Vaults/5_LargeVAULT1.png';
import card90 from './images/cards/Vaults/6_LargeVAULT2.png';
import card91 from './images/cards/Vaults/7_MediumVAULT1.png';
import card92 from './images/cards/Vaults/8_MediumVAULT2.png';
import card93 from './images/cards/Vaults/9_MediumVAULT3.png';
import card94 from './images/cards/Vaults/10_MediumVAULT4.png';
import card95 from './images/cards/Vaults/11_MediumVAULT5.png';
import card96 from './images/cards/Vaults/12_MediumVAULT6.png';
import card97 from './images/cards/Vaults/13_SmallVAULT1.png';
import card98 from './images/cards/Vaults/14_SmallVAULT2.png';
import card99 from './images/cards/Vaults/15_SmallVAULT3.png';
import card100 from './images/cards/Vaults/16_SmallVAULT4.png';
import card101 from './images/cards/Vaults/17_BribersVAULT1.png';
import card102 from './images/cards/Vaults/18_BribersVAULT2.png';
import card103 from './images/cards/Vaults/19_ColdVAULT.png';
import card104 from './images/cards/Vaults/20_DynamicVAULT.png';
import card105 from './images/cards/Vaults/21_EntropicVAULT.png';
import card106 from './images/cards/Vaults/22_CandyVAULT.png';
import card107 from './images/cards/Vaults/23_LonelyVAULT1.png';
import card108 from './images/cards/Vaults/24_LonelyVAULT2.png';
import card109 from './images/cards/Vaults/25_NuclearVAULT.png';
import card110 from './images/cards/Disasters/2_Nuclearmeltdown_DISASTER.png';
import card111 from './images/cards/Disasters/3_SolarDrake_DISASTER.png';
import card112 from './images/cards/Disasters/4_GasExplosion_DISASTER.png';
import card113 from './images/cards/Disasters/6_Meteor_DISASTER.png';
import card114 from './images/cards/Disasters/7_Magmaquake_DISASTER.png';
import card115 from './images/cards/Disasters/8_Star Demon_DISASTER.png';
import card116 from './images/cards/Disasters/5_VerdantChaos_DISASTER.png';
import card117 from './images/cards/Disasters/10_AuroraWhale_DISASTER.png';
import card118 from './images/cards/Disasters/9_SpaceBees_DISASTER.png';
import card119 from './images/cards/Disasters/1_AuroraVeil_DISASTER.png';
import card120 from './images/cards/Disasters/12_solar storm_DISASTER.png';
import card121 from './images/cards/Disasters/11_Ionic Tornado_DISASTER.png';
import card122 from './images/cards/Disasters/16_Voidweavers_CATASTROPHE.png';
import card123 from './images/cards/Disasters/18_EconomicCrash_CATASTROPHE.png';
import card124 from './images/cards/Disasters/13_DOOM_CATASTROPHE.png';
import card125 from './images/cards/Disasters/14_theStarchild_CATASTROPHE.png';
import card126 from './images/cards/Disasters/17_CosmicKoala_CATASTROPHE.png';
import card127 from './images/cards/Disasters/23_GoldenMeteor_CATASTROPHE.png';
import card128 from './images/cards/Disasters/19_AncientAwakening_CATASTROPHE.png';
import card129 from './images/cards/Disasters/24_ExtradimensionalRip_CATASTROPHE.png';
import card130 from './images/cards/Disasters/21_Cultofdoom_CATASTROPHE.png';
import card131 from './images/cards/Disasters/22_TimeAnomaly_CATASTROPHE.png';
import card132 from './images/cards/Disasters/20_CelestialGoddess_CATASTROPHE.png';
import card133 from './images/cards/Disasters/15_thesingularity_CATASTROPHE.png';
export default {
  'card-throne-room-1': card0,
  'card-throne-room-2': card1,
  'card-throne-room-3': card2,
  'card-throne-room-4': card3,
  'card-throne-room-5': card4,
  'card-throne-room-6': card5,
  'card-throne-room-7': card6,
  'card-throne-room-8': card7,
  'card-throne-room-9': card8,
  'card-throne-room-10': card9,
  'card-1-black-market': card10,
  'card-2-divination-chamber': card11,
  'card-3-merchants-guild': card12,
  'card-4-wormhole-generator': card13,
  'card-5-doomsayer': card14,
  'card-6-anti-gravity-cannon': card15,
  'card-7-time-machine': card16,
  'card-8-soothsayer': card17,
  'card-9-architect': card18,
  'card-10-interior-decorator': card19,
  'card-11-treasury': card20,
  'card-12-market': card21,
  'card-13-energy-core': card22,
  'card-14-gravity-generator': card23,
  'card-15-reinforced-walls': card24,
  'card-16-gravitational-anchor': card25,
  'card-17-tavern': card26,
  'card-18-echo-chamber': card27,
  'card-19-armorer': card28,
  'card-20-generator-1': card29,
  'card-21-generator-2': card30,
  'card-22-safe-room': card31,
  'card-23-bunker': card32,
  'card-24-room-with-legs': card33,
  'card-25-spinning-room': card34,
  'card-26-warping-chamber': card35,
  'card-27-imposter': card36,
  'card-28-commanders-chambers': card37,
  'card-29-engineers-workshop': card38,
  'card-30-pilots-quarters': card39,
  'card-31-swimming-pool': card40,
  'card-32-candy-shop': card41,
  'card-33-petting-zoo': card42,
  'card-34-movie-theatre': card43,
  'card-35-nuclear-reactor': card44,
  'card-36-mercenary-1': card45,
  'card-37-mercenary-2': card46,
  'card-38-mercenary-3': card47,
  'card-39-mercenary-4': card48,
  'card-40-giant-fan-1': card49,
  'card-41-giant-fan-2': card50,
  'card-42-construction-crew': card51,
  'card-43-rocket-engineer-1': card52,
  'card-44-rocket-engineer-2': card53,
  'card-45-shuttle-1': card54,
  'card-46-shuttle-2': card55,
  'card-47-warp-cannon-1': card56,
  'card-48-warp-cannon-2': card57,
  'card-49-warp-cannon-3': card58,
  'card-50-hydraulic-arm-1': card59,
  'card-51-hydraulic-arm-2': card60,
  'card-52-watchtower': card61,
  'card-53-homeowners-association': card62,
  'card-54-unhappy-neighbor': card63,
  'card-55-disco': card64,
  'card-56-corridor-1': card65,
  'card-57-corridor-2': card66,
  'card-58-corridor-3': card67,
  'card-59-corridor-4': card68,
  'card-60-corridor-5': card69,
  'card-61-corridor-6': card70,
  'card-62-hallway-1': card71,
  'card-63-hallway-2': card72,
  'card-64-hallway-3': card73,
  'card-65-hallway-4': card74,
  'card-66-hallway-5': card75,
  'card-67-hallway-6': card76,
  'card-68-hallway-7': card77,
  'card-69-staircase-1': card78,
  'card-70-staircase-2': card79,
  'card-71-staircase-3': card80,
  'card-72-staircase-4': card81,
  'card-73-staircase-5': card82,
  'card-74-staircase-6': card83,
  'card-75-staircase-7': card84,
  'card-76-big-vault-1': card85,
  'card-77-big-vault-2': card86,
  'card-78-big-vault-3': card87,
  'card-79-big-vault-4': card88,
  'card-80-large-vault-1': card89,
  'card-81-large-vault-2': card90,
  'card-82-medium-vault-1': card91,
  'card-83-medium-vault-2': card92,
  'card-84-medium-vault-3': card93,
  'card-85-medium-vault-4': card94,
  'card-86-medium-vault-5': card95,
  'card-87-medium-vault-6': card96,
  'card-88-small-vault-1': card97,
  'card-89-small-vault-2': card98,
  'card-90-small-vault-3': card99,
  'card-91-small-vault-4': card100,
  'card-92-bribers-vault-1': card101,
  'card-93-bribers-vault-2': card102,
  'card-94-cold-vault': card103,
  'card-95-dynamic-vault': card104,
  'card-96-entropic-vault': card105,
  'card-97-candy-vault': card106,
  'card-98-lonely-vault-1': card107,
  'card-99-lonely-vault-2': card108,
  'card-100-nuclear-vault': card109,
  'card-d01-nuclear-meltdown': card110,
  'card-d02-nebula-drake': card111,
  'card-d03-gas-explosion': card112,
  'card-d04-meteor': card113,
  'card-d05-magma-quake': card114,
  'card-d06-entropy-demon': card115,
  'card-d07-verdant-chaos': card116,
  'card-d08-aurora-whale': card117,
  'card-d09-space-bees': card118,
  'card-d10-aurora-veil': card119,
  'card-d11-warp-storm': card120,
  'card-d12-ionic-tornado': card121,
  'card-c01-void-weavers': card122,
  'card-c02-economic-crash': card123,
  'card-c03-doom': card124,
  'card-c04-the-star-child': card125,
  'card-c05-cosmic-koala': card126,
  'card-c06-golden-meteor': card127,
  'card-c07-ancient-awakening': card128,
  'card-c08-extra-dimensional-rift': card129,
  'card-c09-cult-of-room': card130,
  'card-c10-time-anomaly': card131,
  'card-c11-celestial-goddess': card132,
  'card-c12-the-singularity': card133,
};